import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AccessibilityLink = makeShortcode("AccessibilityLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <img width="960" alt="Image displaying each variation of InlineMessage, from top: critical, warning, success, unavailable." src="https://github.com/primer/primitives/assets/813754/f9daa010-532a-42e2-b884-45ee6263e17c" />
    <h2>{`Usage`}</h2>
    <p>{`Inline messages inform users about the result of an action they took. In contrast to a `}<a parentName="p" {...{
        "href": "./banner"
      }}>{`banner`}</a>{` an inline message can be very close the where the action has taken place.
For example below an input field, next to a button or within a table.`}</p>
    <h2>{`Anatomy`}</h2>
    <img width="960" alt="A labeled diagram of the inline message component. The labeled parts are an icon and a message." src="https://github.com/primer/primitives/assets/813754/d854b989-ff3b-426b-9eec-0355145e534a" />
    <h2>{`Options`}</h2>
    <h3>{`Variants`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" role="presentation" src="https://github.com/primer/design/assets/813754/c4190734-b9d2-4978-b14e-6ff0e3716efc" />
  <Box as="p" mt="0" mdxType="Box">
        <h4>{`Critical`}</h4>
        <p>{`Use the `}<inlineCode parentName="p">{`critical`}</inlineCode>{` variant for errors. For example, during form validation or when an action failed.`}</p>
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" role="presentation" src="https://github.com/primer/design/assets/813754/7bc1679e-812c-41b6-953b-4ef2ffcd9257" />
  <Box as="p" mt="0" mdxType="Box">
        <h4>{`Warning`}</h4>
        <p>{`Use the `}<inlineCode parentName="p">{`warning`}</inlineCode>{` variant to inform about potential issues or risky changes. For example, important settings and possible connectivity concerns.`}</p>
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" role="presentation" src="https://github.com/primer/design/assets/813754/d3b919f2-4564-43e1-ad31-498d35a09aac" />
  <Box as="p" mt="0" mdxType="Box">
  <h4>Success</h4>
        <p>{`  Use the `}<inlineCode parentName="p">{`success`}</inlineCode>{` variant to indicate task completion or a successfully completed action. For example, to confirm settings have been saved.`}</p>
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" role="presentation" src="https://github.com/primer/design/assets/813754/d0fb73bd-d060-444e-b8c5-0aaff2a54cdc" />
  <Box as="p" mt="0" mdxType="Box">
        <h4>{`Unavailable`}</h4>
        <p>{`Use the `}<inlineCode parentName="p">{`unavailable`}</inlineCode>{` variant to inform users that a specific part of the UI could not be loaded. For example, when not being able to retrieve data in a table.`}</p>
  </Box>
    </Box>
    <h3>{`Size`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" role="presentation" src="https://github.com/primer/design/assets/813754/a59439a4-5f1f-4bc5-b57e-c2e7581e909d" />
  <Box as="p" mt="0" mdxType="Box">
The `InlineMessage` is available as size `small` and `default`. Use the size that best fits to the related UI section. If either works, choose `default`.
  </Box>
    </Box>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="InlineMessage" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      